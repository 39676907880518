.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

#frame{
  height: 100vh;
  width: 100vw;
}
